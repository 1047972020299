<template>
    <div>
        <!-- <h1>asd</h1> -->
        <!-- <Particles id="particles" :options="particles_options" /> -->
        <particles />
        <van-notice-bar color="#1989fa" background="#ecf9ff">
            <span> 您好，欢迎使用 </span>
            <strong>2020年浙江省提升地方高校建设专项——中医大数据诊断与治疗分析平台</strong>
            <span>。</span>
        </van-notice-bar>
        <div class="container" :style="{ width: `${container_width}px` }">
            <!-- <van-button>开始评测</van-button> -->
            <div> </div>
            <brain :width="brain_width" />
            <van-button type="primary" style="margin:0 10px 10px;width:calc(100% - 20px)" round @click="start" :loading="start_loading">开始评测</van-button>
        </div>
    </div>
</template>
<script>
import brain from "../components/brain.vue";
import particles from "../components/particles.vue";
export default {
    components: {
        brain,
        particles,
    },
    data() {
        return {
            container_width: 0,
            brain_width: 0,
            start_loading: false,
            user_state: {},
        };
    },
    created() {
        this.$api("checkUser").then((res) => {
            this.user_state = res;
            if (res.report) {
                this.$router.push("/report");
            }
        });
        window.onresize = () => {
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.container_width = calc([920, 960], [960, 1280], [-40, 1, 0]);
            this.brain_width = calc([576, 640], [960, 1280], [-240, 0.8, 0]);
        };
        window.onresize();
    },
    methods: {
        start() {
            this.start_loading = true;
            if (this.user_state.base_info) {
                this.$router.push("/step1");
            } else {
                this.$router.push("/baseinfo?ct=1");
            }
        },
    },
};
</script>

<style scoped>
.container {
    margin: 10vh auto;
    height: 80vh;
    background: rgba(20, 20, 20, 0.8);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
</style>
